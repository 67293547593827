import React from "react";
import { useTranslation } from 'react-i18next';


const Home = ({ onNavigate }) => {
    const { t } = useTranslation();
    
    return (
        <div className="page">
            <div className="split-container">
                <div className="left-content">
                    <h1 className="title bold-text">
                        {t('titleSimplify')}<span className="highlight-dot"></span>
                        <br />
                        {t('titleAmplify')}<span className="highlight-dot"></span>
                        <br />
                        <span className="gradient-text">{t('titlePoint')}</span>
                        <span className="highlight-dot" style={{ marginLeft: '4px' }}></span>
                    </h1>
                    <p className="subtitle">{t('homeIntro')}</p>
                    <div className="buttons">
                        <button
                            className="primary-button"
                            style={{ backgroundColor: 'var(--orange)', color: 'var(--dark-text)' }}
                            onClick={() => onNavigate('howitworks')}
                        >
                            {t('detailsButton')}
                        </button>
                        <button
                            className="secondary-button"
                            style={{ backgroundColor: 'var(--secondary-color)', color: 'var(--dark-text)' }}
                            onClick={() => onNavigate('usecases')}
                        >
                            {t('useCasesButton')}
                        </button> 
                    </div>
                </div>

                <div className="right-content" style={{ position: 'relative' }}>
                </div>                
            </div>           
        </div>
    );
};

export default Home;
