import * as React from "react";

const SvgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Calque_1"
    data-name="Calque 1"
    version="1.1"
    viewBox="0 0 500 500"
  >
    <circle
      cx="153.607"
      cy="250"
      r="43.5"
      fill="#142645"
      strokeWidth="0"
    ></circle>
    <circle
      cx="271.607"
      cy="250"
      r="117.5"
      fill="none"
      stroke="#fd5c02"
      strokeMiterlimit="10"
    ></circle>
  </svg>
);

export default SvgIcon;
