import React, { useState, useEffect } from "react";
import logo from '../assets/images/LogoClair.png';
import { useTranslation } from 'react-i18next';

import "./NavBar.css";

const NavBar = ({ currentSectionId, onNavigate, onContactClick }) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="navbar">
      {/* Logo Section */}
      <div className="logo-container">
        <img
          src={logo}
          alt="Logo"
          className="logo"
          onClick={() => onNavigate('home')}
        />
      </div>

      {/* Navigation Menu */}
      <ul className="menu">
        {!isMobile && (
          <>
            <li>
              <button
                onClick={() => onNavigate('home')}
                className={currentSectionId === 'home' ? "active" : ""}
              >
                {t('homeButton')}
              </button>
            </li>
            <li>
              <button
                onClick={() => onNavigate('howitworks')}
                className={currentSectionId === 'howitworks' ? "active" : ""}
              >
                {t('howItWorksButton')}
              </button>
            </li>
            <li>
              <button
                onClick={() => onNavigate('usecases')}
                className={currentSectionId === 'usecases' ? "active" : ""}
              >
                {t('useCasesButtonMenu')}
              </button>
            </li>
            <li>
              <button
                onClick={() => onNavigate('features')}
                className={currentSectionId === 'features' ? "active" : ""}
              >
                {t('featuresButtonMenu')}
              </button>
            </li>
          </>
        )}

        {isMobile && (
          <li className="dropdown-mobile">
            <button
              onClick={() => setDropdownOpen(!isDropdownOpen)}
              className={isDropdownOpen ? "active" : ""}
            >
              {t('learnMoreButton')} ▼
            </button>
            <ul className={`dropdown-menu ${isDropdownOpen ? "open" : ""}`}>
              <li>
                <button
                  onClick={() => {
                    setDropdownOpen(false);
                    onNavigate('howitworks');
                  }}
                  className={currentSectionId === 'howitworks' ? "active" : ""}
                >
                  {t('howItWorksButton')}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setDropdownOpen(false);
                    onNavigate('usecases');
                  }}
                  className={currentSectionId === 'usecases' ? "active" : ""}
                >
                  {t('useCasesButtonMenu')}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setDropdownOpen(false);
                    onNavigate('features');
                  }}
                  className={currentSectionId === 'features' ? "active" : ""}
                >
                  {t('featuresButtonMenu')}
                </button>
              </li>
            </ul>
          </li>
        )}

        <li>
          <button onClick={onContactClick} className="gradient-button">
            {t('bookDemoButton')}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
