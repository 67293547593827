import React, { useEffect, useState } from 'react';

const ScrollDown = ({ currentPage, totalSections, visibleSectionsData }) => {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    // Only show the arrow if there are more sections below the current one
    setShowArrow(currentPage < totalSections);
  }, [currentPage, totalSections]);

  const scrollToNextSection = () => {
    const nextSectionData = visibleSectionsData[currentPage]; // currentPage is 1-based index
    if (nextSectionData) {
      const nextSectionElement = document.getElementById(nextSectionData.id);
      if (nextSectionElement) {
        nextSectionElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.log("Next section element not found.");
      }
    } else {
      console.log("No more sections to scroll to.");
    }
  };

  if (!showArrow) return null; // Hide the arrow if there's no section below

  return (
    <div className="scroll-down" onClick={scrollToNextSection}>
      <span>↓</span>
    </div>
  );
};

export default ScrollDown;
