import * as React from "react";

const SvgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Calque_1"
    data-name="Calque 1"
    version="1.1"
    viewBox="0 0 500 500"
  >
    <circle
      cx="211.141"
      cy="252"
      r="182"
      fill="#142645"
      strokeWidth="0"
    ></circle>
    <g fill="none" stroke="#fff" strokeMiterlimit="10">
      <circle cx="224.139" cy="157.74" r="6.72"></circle>
      <circle cx="272.139" cy="157.74" r="6.72"></circle>
      <circle cx="320.139" cy="157.74" r="6.72"></circle>
      <circle cx="368.139" cy="157.74" r="6.72"></circle>
      <circle cx="416.139" cy="157.74" r="6.72"></circle>
      <circle cx="464.139" cy="157.74" r="6.72"></circle>
      <circle cx="224.139" cy="193.58" r="6.72"></circle>
      <circle cx="272.139" cy="193.58" r="6.72"></circle>
      <circle cx="320.139" cy="193.58" r="6.72"></circle>
      <circle cx="368.139" cy="193.58" r="6.72"></circle>
      <circle cx="416.139" cy="193.58" r="6.72"></circle>
      <circle cx="464.139" cy="193.58" r="6.72"></circle>
      <circle cx="224.139" cy="229.42" r="6.72"></circle>
      <circle cx="272.139" cy="229.42" r="6.72"></circle>
      <circle cx="320.139" cy="229.42" r="6.72"></circle>
      <circle cx="368.139" cy="229.42" r="6.72"></circle>
      <circle cx="416.139" cy="229.42" r="6.72"></circle>
      <circle cx="464.139" cy="229.42" r="6.72"></circle>
      <circle cx="224.139" cy="265.26" r="6.72"></circle>
      <circle cx="272.139" cy="265.26" r="6.72"></circle>
      <circle cx="320.139" cy="265.26" r="6.72"></circle>
      <circle cx="368.139" cy="265.26" r="6.72"></circle>
      <circle cx="416.139" cy="265.26" r="6.72"></circle>
      <circle cx="464.139" cy="265.26" r="6.72"></circle>
    </g>
  </svg>
);

export default SvgIcon;
