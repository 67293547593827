import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import './styles.css';
import Home from "./components/Home";
import HowItWorks from "./components/HowItWorks";
import Features from "./components/Features";
import UseCases from "./components/UseCases";
import ContactForm from "./components/ContactForm";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ScrollDown from "./components/ScrollDown";
import HomeSVG from "./assets/svg/introSVG";
import HomeSVGMobile from "./assets/svg/introSVGMobile";
import UseCasesLeftSVG from "./assets/svg/UsecaseLeftSVG";
import UseCaseRightSVG from "./assets/svg/USeCaseRightSVG";
import HowItWorkRight1 from "./assets/svg/HowItWorkRight1SVG";
import FeaturesLeftSVG from "./assets/svg/FeaturesLeftSVG";
import BottomSVG from "./assets/svg/BottomSVG";
import HomePageLeftSVG from "./assets/svg/HomePageLeftSVG";

// Gradient configurations for specific positions in the content
const GRADIENT_CONFIGS = [
  {
    id: 'gradient1',
    type: 'radial-gradient',
    position: { x: 75, y: 50 }, 
    positionMobile: { x: 120, y: 25}, // Top-right on mobile
    colors: {
      primary: 'var(--secondary-color)',
      intermediary : 'var(--primary-color)',
      secondary: 'var(--primary-color)'
      
    },
    size: '750px', 
    sizeMobile: '500px',
    sectionId: 'home' ,
    opacity: 0.1,
    svg: <HomeSVG />, 
    mobileSvg: <HomeSVGMobile />,
    
  },
  {
    id: 'gradient2',
    type: 'radial-gradient',
    position: { x: 10, y: 50 }, 
    colors: {
      primary: 'var(--secondary-color)',
      intermediary : 'var(--primary-color)',
      secondary: 'var(--primary-color)'
      
    },
    size: '500px', 
    sectionId: 'howitworks',
    opacity: 0.2
  },
  {
    id: 'gradient3',
    type: 'radial-gradient',
    position: { x: 50, y: 50 }, 
    colors: {
      primary: 'var(--secondary-color)',
      intermediary : 'var(--primary-color)',
      secondary: 'var(--primary-color)'
      
    },
    size: '1000px', 
    sectionId: 'usecases',
    opacity: 0.2
  },
];

const SVG_CONFIGS = [
  {
    id: 'usecases-svg',
    sectionId: 'usecases',
    position: { x: -13, y: 50 }, 
    size: '550px', 
    svg: <UseCasesLeftSVG />, 
  },

  {
    id: 'howitworksLeft-svg',
    sectionId: 'howitworks',
    position: { x: 105, y: 95 }, 
    size: '550px', 
    svg: <HowItWorkRight1 />, 
  },

  {
    id: 'usecases2-svg',
    sectionId: 'usecases',
    position: { x: 110, y: 70 }, 
    size: '250px', 
    svg: <UseCaseRightSVG />,
  },

  {
    id: 'features-svg',
    sectionId: 'features',
    position: { x: -10, y: 95 }, 
    size: '500px', 
    svg: <FeaturesLeftSVG />,
  },



  {
    id: 'homepageLeft-svg',
    sectionId: 'home',  
    position: { x: -7, y: 60 }, 
    size: '250px', 
    mobilesize : '100px',
    svg: <HomePageLeftSVG />,
  },  
];


function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

function App() {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSectionId, setCurrentSectionId] = useState('home');
  const [showContactForm, setShowContactForm] = useState(false);
  const [totalSections, setTotalSections] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollLocked, setScrollLocked] = useState(false);

  const pagesContainerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Function to handle navigation and scrolling
  const handleNavigation = (sectionId) => {
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      scrollToSection(sectionId);
    }
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element && pagesContainerRef.current) {
      const elementPosition = element.offsetTop - pagesContainerRef.current.offsetTop;
      pagesContainerRef.current.scrollTo({
        top: elementPosition,
        behavior: 'smooth',
      });
    }
  };
  

  const sectionsData = [
    { id: 'home', component: <Home onNavigate={handleNavigation} />, mobileOnly: false, desktopOnly: false },
    { id: 'howitworks', component: <HowItWorks setScrollLocked={setScrollLocked} onNavigate={handleNavigation} />, mobileOnly: false, desktopOnly: false },
    { id: 'usecases', component: <UseCases onNavigate={handleNavigation} />, mobileOnly: false, desktopOnly: false },
    { id: 'features', component: <Features onNavigate={handleNavigation} />, mobileOnly: false, desktopOnly: false },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;
  const visibleSectionsData = sectionsData.filter(section => {
    if (isMobile && section.desktopOnly) {
      return false;
    }
    if (!isMobile && section.mobileOnly) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    setTotalSections(visibleSectionsData.length);
  }, [visibleSectionsData]);

  useEffect(() => {
    const options = {
      root: pagesContainerRef.current,
      rootMargin: '0px',
      threshold: [0.1, 0.5, 0.9], 
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = visibleSectionsData.findIndex(
            (section) => section.id === entry.target.id
          );
          setCurrentPage(index + 1);
          setCurrentSectionId(entry.target.id);
        }
      });
    }, options);

    visibleSectionsData.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      visibleSectionsData.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [visibleSectionsData]);

  useEffect(() => {
    if (pagesContainerRef.current) {
      pagesContainerRef.current.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/' && location.state && location.state.scrollTo) {
      const sectionId = location.state.scrollTo;
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100); // Delay to ensure elements are rendered
    }
  }, [location]);

  const handleContactClick = () => {
    setShowContactForm(true);
  };

  const handleCloseContactForm = () => {
    setShowContactForm(false);
  };

  // Function to render gradient divs within sections
  const renderGradientsInSection = (sectionId) => {
    return GRADIENT_CONFIGS.filter((gradient) => gradient.sectionId === sectionId).map((gradient) => {
      const mainSVG= isMobile && gradient.mobileSvg ? gradient.mobileSvg : gradient.svg;
      const position = isMobile && gradient.positionMobile ? gradient.positionMobile : gradient.position;
      const size = isMobile && gradient.sizeMobile ? gradient.sizeMobile : gradient.size;
      const svgWidth = isMobile ? '180%' : '70%';
      return (
        <React.Fragment key={gradient.id}>
          <div
            className="gradient"
            style={{
              position: 'absolute',
              top: `${position.y}%`,
              left: `${position.x}%`,
              transform: 'translate(-50%, -50%)',
              width: size,
              height: size,
              background: `${gradient.type}(circle, ${gradient.colors.primary}, ${gradient.colors.intermediary}, ${gradient.colors.secondary})`,
              opacity: gradient.opacity || 0.2,
              borderRadius: '50%',
              pointerEvents: 'none',
              zIndex: 0,
            }}
          />
          {mainSVG && (
            <div
              style={{
                position: 'absolute',
                top: `${position.y}%`,
                left: `${position.x}%`,
                transform: 'translate(-50%, -50%)',
                width: svgWidth,
                height: 'auto',
                zIndex: 1,
              }}
            >
              {mainSVG}
            </div>
          )}
        </React.Fragment>
      );
    });
  };  
  const renderSVGsInSection = (sectionId) => {
    return SVG_CONFIGS.filter(svg => svg.sectionId === sectionId)
      .map((svg) => {
        const size = isMobile && svg.mobilesize ? svg.mobilesize : svg.size;
        return (
          <div
            key={svg.id}
            style={{
              position: 'absolute',
              top: `${svg.position.y}%`,
              left: `${svg.position.x}%`,
              transform: 'translate(-50%, -50%)',
              width: size,
              height: 'auto', // Maintain aspect ratio
              zIndex: 0, // Ensure it is above other elements
            }}
          >
            {React.cloneElement(svg.svg, { style: { width: '100%', height: 'auto' } })}
          </div>
        );
      });
  };

  
  return (
    <div className="App">
      <NavBar
        currentSectionId={currentSectionId}
        onContactClick={handleContactClick}
        onNavigate={handleNavigation}

      />

      <Routes>
        <Route
          path="/"
          element={
            <div
              className={`pages-container ${
                currentSectionId === visibleSectionsData[visibleSectionsData.length - 1]?.id
                  ? 'last-page-active'
                  : ''
              }`}
              style={{
                overflowY: scrollLocked ? 'hidden' : 'auto',
                position: 'relative',
              }}
              ref={pagesContainerRef}
            >
              {visibleSectionsData.map((section, index) => (
                <section
                  key={section.id}
                  id={section.id}
                  className={`page ${index === visibleSectionsData.length - 1 ? 'last-page' : ''}`}
                  style={{ position: 'relative' }}
                >
                  {/* Render gradients for this section */}
                  {renderGradientsInSection(section.id)}

                  {/* Render standalone SVGs for this section */}
                  {renderSVGsInSection(section.id)}

                  {/* Section-specific content */}
                  {section.component}
                </section>
              ))}
              <ScrollDown
                currentPage={currentPage}
                totalSections={totalSections}
                visibleSectionsData={visibleSectionsData}
              />
            </div>
          }
        />
      </Routes>

      {showContactForm && <ContactForm onClose={handleCloseContactForm} />}


      {/* Conditionally render Footer based on the current section */}
      {currentSectionId === visibleSectionsData[visibleSectionsData.length - 1]?.id && (
        <Footer onContactClick={handleContactClick} onNavigate={handleNavigation} />
      )}

    </div>
  );
}

export default AppWrapper;
