
import { i, small } from "framer-motion/client";
import UCHR1Image from "../assets/images/UCHR1.png";
import UCHR2Image from "../assets/images/UCHR2.png";
import UCHR3Image from "../assets/images/UCHR3.png";
import UCHR4Image from "../assets/images/UCHR4.png";
import UCPM1Image from "../assets/images/UCPM1.png";
import UCPM1LargeImage from "../assets/images/UCPM1Large.png";
import UCPM2Image from "../assets/images/UCPM2.png";
import UCPM3Image from "../assets/images/UCPM3.png";
import UCMK1Image from "../assets/images/UCMK1.png";
import UCMK1LargeImage from "../assets/images/UCMK1Large.png";
import UCMK2Image from "../assets/images/UCMK2.png";
import UCMK3Image from "../assets/images/UCMK3.png";
import UCCX1Image from "../assets/images/UCCX1.png";
import UCCX2Image from "../assets/images/UCCX2.png";
import UCCX3Image from "../assets/images/UCCX3.png";
import UCCX4Image from "../assets/images/UCCX4.png";



export const cardDataSets = {
  1: {
    desktop: [
      { image: UCPM1LargeImage, content: "Product example 1", position: { x: "400px", y: "0px", z: 3 }, width: "300px", height: "300px" },
      { image: UCPM2Image, content: "roduct example 2", position: { x: "0px", y: "50px", z: 4 }, width: "350px", height: "50px" },
      { image: UCPM3Image, content: "roduct example 3", position: { x: "70px", y: "120px", z: 5 }, width: "360px", height: "120px" },
    ],
    tallerMobile: [
      { image: UCPM1Image, content: "Product example 1", position: { x: "100px", y: "0px", z: 3 }, width: "140px", height: "400px" },
      { image: UCPM2Image, content: "Product example 2", position: { x: "0px", y: "120px", z: 4 }, width: "200px", height: "40px" },
      { image: UCPM3Image, content: "Product example 3", position: { x: "10px", y: "250px", z: 5 }, width: "300px", height: "120px" },
    ],
    smallMobile : [
      { image: UCPM1Image, content: "Product example 1", position: { x: "100px", y: "0px", z: 3 }, width: "150px", height: "300px" },
      { image: UCPM2Image, content: "Product example 2", position: { x: "0px", y: "100px", z: 4 }, width: "200px", height: "40px" },
      { image: UCPM3Image, content: "Pnproduct example 3", position: { x: "5px", y: "180px", z: 5 }, width: "230px", height: "80px" },
    ],
  },
  2: {
    desktop: [
      { image: UCMK1LargeImage, content: "Comment 1", position: { x: "7px", y: "0px", z: 3 }, width: "450px", height: "250px" },
      { image: UCMK2Image, content: "Comment 2", position: { x: "350px", y: "10px", z: 4 }, width: "350px", height: "50px" },
      { image: UCMK3Image, content: "Comment 4", position: { x: "200px", y: "120px", z: 5 }, width: "360px", height: "120px" },
    ],
    tallerMobile: [
      { image: UCMK1Image, content: "Comment 1", position: { x: "0px", y: "0px", z: 3 }, width: "140px", height: "350px" },
      { image: UCMK2Image, content: "Comment 2", position: { x: "50px", y: "50px", z: 4 }, width: "200px", height: "40px" },
      { image: UCMK3Image, content: "Comment 4", position: { x: "50px", y: "200px", z: 5 }, width: "200px", height: "100px" },
    ],
    smallMobile : [
      { image: UCMK1Image, content: "Comment 1", position: { x: "0px", y: "0px", z: 3 }, width: "100px", height: "250px" },
      { image: UCMK2Image, content: "Comment 2", position: { x: "50px", y: "50px", z: 4 }, width: "200px", height: "40px" },
      { image: UCMK3Image, content: "Comment 4", position: { x: "50px", y: "150px", z: 5 }, width: "200px", height: "80px" },
    ],
  },
  3: {
    desktop: [
      { image: UCHR1Image, content: "Comment 1", position: { x: "0px", y: "0px", z: 1 }, width: "450px", height: "80px" },
      { image: UCHR2Image, content: "Comment 2", position: { x: "500px", y: "120px", z: 4 }, width: "200px", height: "100px" },
      { image: UCHR3Image, content: "Comment 4", position: { x: "20px", y: "130px", z: 2 }, width: "550px", height: "120px" },
      { image: UCHR4Image, content: "Comment 5", position: { x: "350px", y: "40px", z: 3 }, width: "300px", height: "120px" },
      ],
    
  tallerMobile: [
    { image: UCHR1Image, content: "Comment 1", position: { x: "50px", y: "20px", z: 1 }, width: "200px", height: "40px"},
    { image: UCHR2Image, content: "Comment 2", position: { x: "70px", y: "160px", z: 4 }, width: "160px", height: "80px"},
    { image: UCHR3Image, content: "Comment 4", position: { x: "0px", y: "220px", z: 2 }, width: "250px", height: "120px" },
    { image: UCHR4Image, content: "Comment 5", position: { x: "0px", y: "55px", z: 3 }, width: "240px", height: "100px" },
  ],
  smallMobile: [
    { image: UCHR1Image, content: "Comment 1", position: { x: "50px", y: "5px", z: 1 }, width: "200px", height: "40px" },
    { image: UCHR2Image, content: "Comment 2", position:  { x: "70px", y: "150px", z: 4 }, width: "160px", height: "80px"},
    { image: UCHR3Image, content: "Comment 4", position: { x: "0px", y: "210px", z: 2 }, width: "250px", height: "90px" },
    { image: UCHR4Image, content: "Comment 5", position: { x: "0px", y: "45px", z: 3 }, width: "240px", height: "100px" },
  ]
  },
  

  4: {
    desktop: [
      { image: UCCX3Image , content: "CX example 1", position: { x: "0px", y: "0px", z: 3 }, width: "200px", height: "100px" },
      { image: UCCX4Image, content: "CX example 2", position: { x: "500px", y: "50px", z: 4 }, width: "200px", height: "170px" },
      { image: UCCX1Image, content: "CX example 4", position: { x: "150px", y: "70px", z: 5 }, width: "300px", height: "180px" },
      { image: UCCX2Image, content: "CX example 3", position: { x: "250px", y: "10px", z: 1 }, width: "250px", height: "100px" },
    ],
    tallerMobile: [
      { image: UCCX3Image , content: "CX example 1", position: { x: "0px", y: "0px", z: 3 }, width: "80px", height: "80px" },
      { image: UCCX4Image, content: "CX example 2", position: { x: "50px", y: "50px", z: 4 }, width: "150px", height: "130px" },
      { image: UCCX1Image, content: "CX example 4", position: { x: "-10px", y: "200px", z: 5 }, width: "280px", height: "180px" },
      { image: UCCX2Image, content: "CX example 3", position: { x: "130px", y: "10px", z: 1 }, width: "150px", height: "60px" },

    ],
    smallMobile : [
      { image: UCCX3Image , content: "CX example 1", position: { x: "0px", y: "0px", z: 3 }, width: "80px", height: "80px" },
      { image: UCCX4Image, content: "CX example 2", position: { x: "50px", y: "50px", z: 4 }, width: "150px", height: "130px" },
      { image: UCCX1Image, content: "CX example 4", position: { x: "-10px", y: "120px", z: 5 }, width: "280px", height: "180px" },
      { image: UCCX2Image, content: "CX example 3", position: { x: "130px", y: "10px", z: 1 }, width: "150px", height: "60px" },

    ],
  }
 
}


