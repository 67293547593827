import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ContactForm.css';

const ContactForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ name: '', email: '', message: '', botcheck: '' });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({ type: '', message: '' }); // Consolidated state for feedback messages
  const [isCopied, setIsCopied] = useState(false);

  // Base64-encoded email to obfuscate it from bots
  const encodedEmail = 'aW5mb0B0b3RoZXBvaW50YXBwLmNvbQ=='; // 'info@tothepointapp.com'
  const decodedEmail = atob(encodedEmail);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateFields = () => {
    const validationErrors = {};
    if (!formData.name.trim()) validationErrors.name = t('nameRequired');
    if (!formData.email.trim()) validationErrors.email = t('emailRequired');
    else if (!/\S+@\S+\.\S+/.test(formData.email)) validationErrors.email = t('invalidEmail');
    if (!formData.message.trim()) validationErrors.message = t('messageRequired');
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    // Honeypot validation: botcheck should be empty
    if (formData.botcheck) {
      setResult({ type: 'error', message: t('botDetected') });
      setLoading(false);
      return;
    }

    const formDataToSend = {
      access_key: '12760e7b-7e57-470a-9214-9c00baa07ac4',
      name: formData.name,
      email: formData.email,
      message: formData.message,
      botcheck: '', // Must remain empty
    };

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formDataToSend),
      });

      if (response.status === 200) {
        setResult({ type: 'success', message: t('thankYouMessage') });
        setFormData({ name: '', email: '', message: '', botcheck: '' }); // Reset form
      } else {
        setResult({ type: 'error', message: t('submissionFailed') });
      }
    } catch (error) {
      console.error('Submission error:', error);
      setResult({ type: 'error', message: t('submissionError') });
    } finally {
      setLoading(false);
      setTimeout(() => setResult({ type: '', message: '' }), 3000); // Clear result after 3 seconds
    }
  };

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(decodedEmail).then(() => {
      setIsCopied(true); // Set copied state to true
      setTimeout(() => setIsCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    <div className="contact-form-overlay">
      <div className="contact-form-container">
        <button
          className="close-button"
          onClick={onClose}
          aria-label={t('closeForm')}
        >
          &times;
        </button>

        <h2 className="form-title">{t('contact')}</h2>
        <p className="form-intro">{t('contactFormPurpose')}</p>
        <p className="form-alternativeintro">{t('alternativeContact1')}</p>
        <p className="form-alternative">
          {t('alternativeContact')}{' '}
          <a href={`mailto:${decodedEmail}`}>{decodedEmail}</a>
          <button
            type="button"
            className={`copy-button ${isCopied ? 'copied' : ''}`}
            onClick={handleCopyEmail}
          >
            {isCopied ? t('copied') : t('copy')}
          </button>
        </p>

        {result.message && (
          <p className={result.type === 'success' ? 'success-message' : 'error-message'}>
            {result.message}
          </p>
        )}

        <form onSubmit={handleSubmit} noValidate>
          {/* Honeypot Field */}
          <input
            type="text"
            name="botcheck"
            style={{ display: 'none' }}
            value={formData.botcheck}
            onChange={handleChange}
            autoComplete="off"
          />

          <div className="form-group">
            <label htmlFor="name">{t('contactNameField')}</label>
            <input
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              className={errors.name ? 'input-error' : ''}
              placeholder={t('namePlaceholder')}
              required
            />
            {errors.name && <span className="error-text">{errors.name}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="email">{t('contactEmailField')}</label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? 'input-error' : ''}
              placeholder={t('emailPlaceholder')}
              required
            />
            {errors.email && <span className="error-text">{errors.email}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="message">{t('contactMessageField')}</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              className={errors.message ? 'input-error' : ''}
              placeholder={t('messagePlaceholder')}
              required
            />
            {errors.message && <span className="error-text">{errors.message}</span>}
          </div>

          <button
            type="submit"
            className="submit-button"
            disabled={loading}
          >
            {loading ? t('sending') : t('contactSendButton')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
