import React from "react";

const Card = ({ image, content, position, width, height }) => {
  return (
    <div
      className="card"
      style={{
        position: "absolute", // Relative to .showcase-container
        position: "absolute", // Relative to .showcase-container
        top: position.y, // Place based on container
        left: position.x, // Place based on container
        width: width,
        height: height,
        zIndex: position.z, // Layering
        backgroundColor: "white", 
        borderRadius: "var(--corner-radius)",
        boxShadow: "0 5px 25px rgba(0, 0, 0, 0.8)", 
        overflow: "hidden",  
        display: "flex",      
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="card-image"
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <img
          src={image}
          alt={content}
          style={{
            width: "95%",
            height: "95%",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
};


export default Card;
