import React, { useEffect, useRef, useState, useCallback } from 'react';
import useScrollLock from './useScrollLock';
import { useTranslation } from 'react-i18next';

import './HowItWorks.css';

function HowItWorks({ setScrollLocked, onNavigate }) {
  const { t } = useTranslation();
  const paragraphs = [
    t('howItWorks1'),
    t('howItWorks2'),
    t('howItWorks3'),
    t('howItWorks4'),
  ];

  const sectionRef = useRef(null);
  const bulletRefs = useRef([]);
  const lineRefs = useRef([]);
  const [isInView, setIsInView] = useState(false);

  const {
    currentIndex: currentParagraphIndex,
    handleScroll,
    handleTouchStart,
    handleTouchMove,
    setCurrentIndex
  } = useScrollLock(paragraphs.length, isInView);

  const handleKeyDown = useCallback((e) => {
    if ((e.key === 'ArrowDown' || e.key === 'ArrowRight') && currentParagraphIndex < paragraphs.length - 1) {
      e.preventDefault();
      setCurrentIndex((prev) => Math.min(prev + 1, paragraphs.length - 1));
    } else if ((e.key === 'ArrowUp' || e.key === 'ArrowLeft') && currentParagraphIndex > 0) {
      e.preventDefault();
      setCurrentIndex((prev) => Math.max(prev - 1, 0));
    }
  }, [currentParagraphIndex, paragraphs.length, setCurrentIndex]);

  // Intersection Observer to detect visibility of the section
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6,
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        setIsInView(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Event listeners for scroll, touch, and keyboard navigation
  useEffect(() => {
    const sectionElement = sectionRef.current;
    if (!sectionElement) return;

    if (isInView) {
      sectionElement.addEventListener('wheel', handleScroll, { passive: false });
      sectionElement.addEventListener('touchstart', handleTouchStart, { passive: false });
      sectionElement.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('keydown', handleKeyDown);
    } else {
      sectionElement.removeEventListener('wheel', handleScroll);
      sectionElement.removeEventListener('touchstart', handleTouchStart);
      sectionElement.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      sectionElement.removeEventListener('wheel', handleScroll);
      sectionElement.removeEventListener('touchstart', handleTouchStart);
      sectionElement.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isInView, handleScroll, handleTouchStart, handleTouchMove, handleKeyDown]);

  // Dynamically calculate and set progress line heights
  useEffect(() => {
    bulletRefs.current.forEach((bullet, index) => {
      if (index < lineRefs.current.length && bullet && bulletRefs.current[index + 1]) {
        const nextBullet = bulletRefs.current[index + 1];
        const line = lineRefs.current[index];

        const distance = nextBullet.getBoundingClientRect().top - bullet.getBoundingClientRect().top;
        line.style.height = `${distance}px`;

        // Fill the line for completed sections
        if (index < currentParagraphIndex) {
          line.classList.add('filled');
        } else {
          line.classList.remove('filled');
        }
      }
    });
  }, [currentParagraphIndex]);

  // Handle click to advance paragraphs
  const handleClick = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, paragraphs.length - 1));
  };

  return (
    <div className="page">
      <div className="how-it-works-section" ref={sectionRef} tabIndex="0" onClick={handleClick}>
        <div className="unique-container">
          <div className="intro-intro gradient-text bold-text"> 
            <p>{t('howItWorksIntro1')}</p>
          </div>
          <div className="intro-text bold-text" style={{ marginBottom: '0.5rem' }}>
            <p>{t('howItWorksIntro2')}</p>
          </div>

          <div className="progress-and-paragraphs">
            {paragraphs.map((text, index) => (
              <div key={index} className="bullet-and-paragraph">
                <div className="bullet-container">
                  <button
                    ref={(el) => (bulletRefs.current[index] = el)}
                    className={`bullet ${index <= currentParagraphIndex ? 'active' : ''}`}
                    aria-label={`Go to paragraph ${index + 1}`}
                  ></button>
                  {index < paragraphs.length - 1 && (
                    <div
                      ref={(el) => (lineRefs.current[index] = el)}
                      className="progress-line"
                      aria-hidden="true"
                    ></div>
                  )}
                </div>
                <div className={`paragraph ${index <= currentParagraphIndex ? 'visible' : ''}`}>
                  {text}
                </div>
              </div>
            ))}
            <div className="buttons">
              <button
                className="primary-button"
                style={{ backgroundColor: 'var(--orange)', color: 'var(--dark-text)', zIndex: 1 }}
                onClick={() => onNavigate('usecases')}
              >
                {t('useCasesButton')}
              </button>
              <button
                className="secondary-button"
                style={{ backgroundColor: 'var(--secondary-color)', color: 'var(--dark-text)' }}
                onClick={() => onNavigate('features')}
              >
                {t('featuresButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
