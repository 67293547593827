import * as React from "react";

const SvgIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 500">
    <g id="Calque_1" data-name="Calque 1">
      <path
        fill="#142645"
        strokeWidth="0"
        d="M362.945 128.682c-67.002 0-121.318 54.316-121.318 121.318s54.316 121.318 121.318 121.318S484.263 317.002 484.263 250s-54.316-121.318-121.318-121.318m0 185.549c-35.474 0-64.231-28.757-64.231-64.231s28.757-64.231 64.231-64.231 64.231 28.757 64.231 64.231-28.757 64.231-64.231 64.231"
        opacity="0.08"
      ></path>
      <circle
        cx="362.945"
        cy="246.061"
        r="25.021"
        fill="#142645"
        strokeWidth="0"
        opacity="0.08"
        transform="rotate(-45 362.945 246.06)"
      ></circle>
      <circle
        cx="362.945"
        cy="250"
        r="171.5"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
      ></circle>
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        d="M363.001 246.061H808.77"
      ></path>
      <path
        fill="#fff"
        strokeWidth="0"
        d="M425.778 246.042a1.729 1.729 0 1 0 3.457.037 1.729 1.729 0 0 0-3.457-.037M482.778 246.042a1.729 1.729 0 1 0 3.457.037 1.729 1.729 0 0 0-3.457-.037M532.778 246.042a1.729 1.729 0 1 0 3.457.037 1.729 1.729 0 0 0-3.457-.037"
      ></path>
      <circle
        cx="362.945"
        cy="246.061"
        r="9.381"
        fill="#5e87ff"
        strokeWidth="0"
        transform="rotate(-45 362.945 246.06)"
      ></circle>
    </g>
  </svg>
);

export default SvgIcon;
