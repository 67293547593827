import * as React from "react";

const SvgIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
    <g id="Calque_1" data-name="Calque 1">
      <path
        fill="#142645"
        strokeWidth="0"
        d="M928.754 500c0-236.906-191.894-428.993-428.724-429.31h-.06C263.14 71.007 71.246 263.095 71.246 500S263.14 928.993 499.97 929.31h.06c236.83-.317 428.724-192.405 428.724-429.31M500 759.18c-142.884-.3-258.624-116.223-258.624-259.18S357.116 241.12 500 240.82c142.884.3 258.624 116.223 258.624 259.18S642.884 758.88 500 759.18"
        opacity="0.3"
      ></path>
      <circle
        cx="494.603"
        cy="500.801"
        r="139.583"
        fill="#142645"
        strokeWidth="0"
        opacity="0.4"
        transform="rotate(-45 494.604 500.8)"
      ></circle>
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        d="M498.97 495.62h209.61l42.12-52.844"
      ></path>
      <path
        fill="#fd5c02"
        strokeWidth="0"
        d="M750.202 456.282q-5.847 0-9.766-3.918-3.918-3.92-3.918-9.766 0-5.908 3.918-9.796 3.92-3.887 9.766-3.888 5.909 0 9.796 3.888c3.887 3.888 3.889 5.857 3.889 9.796q0 5.847-3.889 9.766-3.887 3.918-9.796 3.918"
      ></path>
    </g>
  </svg>
);

export default SvgIcon;
