import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
import enTranslation from '../src/locales/en/translation.json';
import frTranslation from '../src/locales/fr/translation.json';
import nlTranslation from '../src/locales/nl/translation.json';

// Configure i18n instance
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },

  nl: {
    translation: nlTranslation,
    },
  },
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
