import React from "react";
import Card from "./CardComponent";

const Showcase = ({ cards }) => {
  return (
    <div
      className="image-container"
      style={{
        position: "relative", // Absolute positioning of children based on this container
        overflow: "hidden", // Prevent overflow
      }}
    >
      {cards.map((card, index) => (
        <Card
          key={index}
          image={card.image}
          content={card.content}
          position={card.position} // Pass the randomized position
          width={card.width} // Customizable card width
          height={card.height} // Customizable card height
        />
      ))}
    </div>
  );
};


export default Showcase;
