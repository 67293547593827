import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import arrowLeft from "../assets/images/arrow_left.png";
import arrowRight from "../assets/images/arrow_right.png";
import "./UseCases.css";
import Showcase from "../assets/Resusablecomponents/ShowCase";
import { cardDataSets } from "./useCaseCardData";

const UseCaseCarousel = () => {
  const { t } = useTranslation();

  const useCases = useMemo(() => [
    {
      id: 1,
      title: t('useCase1Title'),
      text: t('useCase1'),
    },
    {
      id: 2,
      title: t('useCase2Title'),
      text: t('useCase2'),
    },
    {
      id: 3,
      title: t('useCase3Title'),
      text: t('useCase3'),
    },
    {
      id: 4,
      title: t('useCase4Title'),
      text: t('useCase4'),
    },
  ], [t]);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const containerRef = useRef(null);
  const isScrollingRef = useRef(false);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  useEffect(() => {
    scrollToIndex(1); // Scroll to use case 2 on initial render
  }, []); 
  

  // Handle window resize to update windowWidth and windowHeight
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Determine device type based on window dimensions
  const deviceType = useMemo(() => {
    if (windowWidth >= 768) {
      return "desktop";
    } else if (windowWidth <= 768 && windowHeight > 800) {
      return "tallerMobile";
    } else if (windowWidth <= 768 && windowHeight <= 800) {
      return "smallMobile";
    } else {
      return "desktop";
    }
  }, [windowWidth, windowHeight]);

  // Calculate the scroll position based on index
  const calculateScrollPosition = useCallback((index) => {
    const container = containerRef.current;
    if (!container) return 0;

    const itemWidth = container.offsetWidth;
    const maxScrollPosition = (useCases.length - 1) * itemWidth;
    let scrollPosition = index * itemWidth;

    scrollPosition = Math.max(0, Math.min(scrollPosition, maxScrollPosition));
    return scrollPosition;
  }, [useCases.length]);

  const scrollToIndex = (index) => {
    const container = containerRef.current;
    if (container) {
      const scrollPosition = calculateScrollPosition(index);
      isScrollingRef.current = true;
      container.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
      setCurrentIndex(index);
    }
  };

  // Handle scroll direction
  const handleScroll = useCallback(
    (direction) => {
      let newIndex = currentIndex;
      if (direction === "left") {
        newIndex = Math.max(currentIndex - 1, 0);
      } else if (direction === "right") {
        newIndex = Math.min(currentIndex + 1, useCases.length - 1);
      }
      scrollToIndex(newIndex);
    },
    [currentIndex, useCases.length]
  );

  const handleScrollEvent = useCallback(() => {
    if (isScrollingRef.current) {
      isScrollingRef.current = false;
      return;
    }

    const container = containerRef.current;
    if (container) {
      const itemWidth = container.offsetWidth;
      const newIndex = Math.round(container.scrollLeft / itemWidth);
      if (newIndex !== currentIndex && newIndex >= 0 && newIndex < useCases.length) {
        setCurrentIndex(newIndex);
      }
    }
  }, [currentIndex, useCases.length]);

  useEffect(() => {
    const container = containerRef.current;
    let animationFrameId;

    const onScroll = () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      animationFrameId = requestAnimationFrame(handleScrollEvent);
    };

    if (container) {
      container.addEventListener("scroll", onScroll);
      return () => {
        container.removeEventListener("scroll", onScroll);
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [handleScrollEvent]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'ArrowLeft') {
        handleScroll('left');
      } else if (e.key === 'ArrowRight') {
        handleScroll('right');
      } else if (e.key === 'Escape' && isZoomed) {
        setIsZoomed(false);
      }
    },
    [handleScroll, isZoomed]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // Handle bullet click
  const handleBulletClick = useCallback((index) => {
    scrollToIndex(index);
  }, [scrollToIndex]);

  // Mouse events for dragging
  const handleMouseDown = (e) => {
    const container = containerRef.current;
    if (!container) return;

    isDraggingRef.current = true;
    container.classList.add('dragging'); // Prevent text selection
    startXRef.current = e.pageX - container.offsetLeft;
    scrollLeftRef.current = container.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDraggingRef.current = false;
    const container = containerRef.current;
    if (container) {
      container.classList.remove('dragging');
    }
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    const container = containerRef.current;
    if (container) {
      container.classList.remove('dragging');
    }
  };

  const handleMouseMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 3; // Adjust scrolling speed
    containerRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleTouchStart = (e) => {
    isDraggingRef.current = true;
    startXRef.current = e.touches[0].pageX - containerRef.current.offsetLeft;
    scrollLeftRef.current = containerRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDraggingRef.current) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 3;
    containerRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleTouchEnd = () => {
    isDraggingRef.current = false;
  };

  const handleCardsClick = (index) => {
    setCurrentIndex(index);
    setIsZoomed(true);
  };

  return (
    <div className="page">
      <div className="unique-container">
        <div className="carousel-container" role="region" aria-label="Use Cases Carousel">
          {/* Intro text */}
          <div className="intro-intro gradient-text bold-text" style={{ textAlign: 'center', marginTop: '2rem' }}>
            {t('introUseCase1')}
          </div>

          {/* Carousel Content */}
          <div className="carousel-content">
            <div
              className="carousel-items"
              ref={containerRef}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              {useCases.map((useCase, index) => (
                <div key={useCase.id} className="carousel-item">
                  {/* Title */}
                  <div className="intro-text bold-text" style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    <p style={{ width: '100%' }}>{useCase.title}</p>
                  </div>

                  {/* Cards Container with Zoom */}
                  <div
                    className="image-container"
                    onClick={() => handleCardsClick(index)}
                    style={{ cursor: 'pointer' }}
                    aria-label="Zoom Use Case Cards"
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleCardsClick(index);
                    }}
                  >
                    {cardDataSets[useCase.id]?.[deviceType]?.length > 0 ? (
                      <Showcase cards={cardDataSets[useCase.id][deviceType]} />
                    ) : (
                      <div className="no-data">
                        <p></p>
                      </div>
                    )}
                  </div>

                  {/* Text */}
                  <p className="use-case-text">{useCase.text}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Zoom Modal */}
          {isZoomed && (
            <div className="zoom-modal" onClick={() => setIsZoomed(false)}>
              <div className="zoomed-image">
                {cardDataSets[useCases[currentIndex]?.id]?.[deviceType]?.length > 0 ? (
                  <Showcase cards={cardDataSets[useCases[currentIndex].id][deviceType]} />
                ) : (
                  <div className="no-data">
                    <p></p>
                  </div>
                )}
              </div>
              <button
                className="close-button"
                onClick={() => setIsZoomed(false)}
                aria-label="Close Zoomed Cards"
              >
                &times;
              </button>
            </div>
          )}

          {/* Arrows and Dots */}
          <div className="scroll-arrows">
            <button
              onClick={() => handleScroll("left")}
              disabled={currentIndex === 0}
              aria-label="Previous Use Case"
              className="nav-button"
            >
              <div className="arrow-bg">
                <img
                  src={arrowLeft}
                  className={`arrow ${currentIndex === 0 ? "disabled" : ""}`}
                  alt="Previous"
                />
              </div>
            </button>
            <div className="dots-container">
              {useCases.map((useCase, index) => (
                <span
                  key={useCase.id}
                  className={`dot ${index === currentIndex ? "active-dot" : ""}`}
                  onClick={() => handleBulletClick(index)}
                />
              ))}
            </div>
            <button
              onClick={() => handleScroll("right")}
              disabled={currentIndex === useCases.length - 1}
              aria-label="Next Use Case"
              className="nav-button"
            >
              <div className="arrow-bg">
                <img
                  src={arrowRight}
                  className={`arrow ${currentIndex === useCases.length - 1 ? "disabled" : ""}`}
                  alt="Next"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCaseCarousel;
