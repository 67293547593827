import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import languageIcon from "../assets/images/language.png";

const Footer = ({ onContactClick }) => {
  const { i18n, t } = useTranslation();

  const handleLanguageChange = (event) => {
    const selectedLang = event.target.value.toLowerCase(); // Convert to lowercase to match i18n codes
    i18n.changeLanguage(selectedLang);
  };

  // Determine the selected language based on i18n's current language
  const getSelectedLanguage = () => {
    switch (i18n.language) {
      case 'fr':
        return 'FR';
      case 'nl':
        return 'NL';
      case 'en':
      default:
        return 'EN';
    }
  };

  return (
    <footer className="footer">
      <div className="footer-line"></div>
      <div className="footer-content">
        <div className="language-selector">
          <img src={languageIcon} alt="Language" className="language-icon" />
          <select 
            value={getSelectedLanguage()} 
            onChange={handleLanguageChange} 
            className="language-dropdown"
          >
            <option value="EN">English</option>
            <option value="FR">Français</option>
            <option value="NL">Nederlands</option>
          </select>
        </div>
       
        <div className="footer-links">
          <button onClick={onContactClick}>{t('contact')}</button>
          {/* <button onClick={() => onNavigate('home')}>{t('terms')}</button> */}
          <span>@ 2024 Tothepoint</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
