import { useState, useEffect, useRef } from 'react';

function useScrollLock(paragraphsLength, isInView) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isScrollingRef = useRef(false);
  const touchStartYRef = useRef(null);
  const currentIndexRef = useRef(currentIndex);

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  const handleScroll = (event) => {
    if (!isInView) return;
    if (isScrollingRef.current) return;
    isScrollingRef.current = true;

    if (currentIndexRef.current >= paragraphsLength - 1) {
      isScrollingRef.current = false;
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, paragraphsLength - 1));

    setTimeout(() => {
      isScrollingRef.current = false;
    }, 100);
  };

  const handleTouchStart = (event) => {
    if (!isInView) return;
    touchStartYRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (!isInView) return;
    if (isScrollingRef.current) return;
    isScrollingRef.current = true;

    if (currentIndexRef.current >= paragraphsLength - 1) {
      isScrollingRef.current = false;
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, paragraphsLength - 1));

    setTimeout(() => {
      isScrollingRef.current = false;
    }, 100);
  };

  return {
    currentIndex,
    setCurrentIndex, // Return this to allow external updates
    handleScroll,
    handleTouchStart,
    handleTouchMove,
  };
}

export default useScrollLock;
