import React, { useState, useRef, useEffect } from 'react';
import './Features.css';
import { useTranslation } from 'react-i18next';
import image1 from '../assets/svg/Feature1.svg';
import image2 from '../assets/svg/Feature2.svg';  
import image3 from '../assets/svg/Feature3.svg';
import image4 from '../assets/svg/Feature4.svg';
import image5 from '../assets/svg/Feature5.svg';
import image6 from '../assets/svg/Feature6.svg';  
import image7 from '../assets/svg/Feature7.svg';
import image8 from '../assets/svg/Feature8.svg';
import image9 from '../assets/svg/Feature9.svg';
import image10 from '../assets/svg/Feature10.svg';
import image11 from '../assets/svg/Feature11.svg';

function Features() {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const additionalFeaturesRef = useRef(null); // Ref for the first additional feature

  const features = [
    { id: 1, title: t('feature1Title'), description: t('feature1'), icon: image1 },
    { id: 2, title: t('feature2Title'), description: t('feature2'), icon: image2 },
    { id: 3, title: t('feature3Title'), description: t('feature3'), icon: image3 },
    { id: 4, title: t('feature4Title'), description: t('feature4'), icon: image4 },
    { id: 5, title: t('feature5Title'), description: t('feature5'), icon: image5 },
    { id: 6, title: t('feature6Title'), description: t('feature6'), icon: image6 },
    { id: 7, title: t('feature7Title'), description: t('feature7'), icon: image7 },
    { id: 8, title: t('feature8Title'), description: t('feature8'), icon: image8 },
    { id: 9, title: t('feature9Title'), description: t('feature9'), icon: image9 },
    { id: 10, title: t('feature10Title'), description: t('feature10'), icon: image10 },
    { id: 11, title: t('feature11Title'), description: t('feature11'), icon: image11 },

  ];  

  // Show first 6 features by default, and all if showAll is true
  const visibleFeatures = showAll ? features : features.slice(0, 6);

  const handleShowAll = () => {
    setShowAll(true);
  };

  useEffect(() => {
    if (showAll && additionalFeaturesRef.current) {
      // Get the position of the additional features
      const elementPosition = additionalFeaturesRef.current.getBoundingClientRect().top + window.pageYOffset;
      const offset = 100; 
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
    }
  }, [showAll]);

  return (
    <div className="page">
      <div className="unique-container">
        <div
          className="intro-intro gradient-text bold-text"
          style={{ textAlign: 'center', marginTop: '1rem' }}
        >
          {t('introTitle')}
        </div>
        <div
          className="intro-text bold-text"
          style={{ textAlign: 'center', marginBottom: '1rem' }}
        >
          <p style={{ width: '100%' }}>{t('introDescription')}</p>
        </div>

        <div className="features-grid" style={{ width: '90%' }}>
          {visibleFeatures.map((feature, index) => {
            // Number of columns in the grid
            const numColumns = 3; 
            // Calculate the total number of rows
            const numRows = Math.ceil(visibleFeatures.length / numColumns);
            // Determine the row and column position for the current card
            const rowIndex = Math.floor(index / numColumns);
            const colIndex = index % numColumns;
            // Calculate background size and position
            const backgroundSize = `${numColumns * 100}% ${numRows * 100}%`;
            const backgroundPositionX = `${(100 / (numColumns - 1)) * colIndex}%`;
            const backgroundPositionY = `${(100 / (numRows - 1)) * rowIndex}%`;

            // Assign ref to the first additional feature
            const isFirstAdditional = showAll && feature.id === 7;
            const isMobile = window.innerWidth <= 768; // Use a breakpoint for mobile


            return (
              <div
                key={feature.id}
                className="feature"
                style={{
                  backgroundImage: 'linear-gradient(to right, var(--orange), var(--pink))',
                  backgroundSize: isMobile ? `${visibleFeatures.length * 100}% 100%` : backgroundSize, // Adjust size for mobile
                  backgroundPosition: isMobile
                    ? `${(100 / (visibleFeatures.length - 1)) * index}% 0%` // Increment gradient by index for mobile
                    : `${backgroundPositionX} ${backgroundPositionY}`, // left to right logic for desktop
                  backgroundRepeat: 'no-repeat',
                }}
                ref={isFirstAdditional ? additionalFeaturesRef : null}
              >
                <div className="feature-icon">
                  <img src={feature.icon} alt={feature.title} />
                </div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      {features.length > 6 && !showAll && (
        <div style={{ textAlign: 'center', marginTop: '5px' }}>
          <button
            className="primary-button"
            style={{ backgroundColor: 'var(--secondary-color)', color: 'var(--dark-text)' }}
            onClick={handleShowAll}
          >
            {t('moreFeaturesButton')}
          </button>
        </div>
      )}
    </div>
  );
}

export default Features;
